import React, {useEffect, useRef, useState} from 'react';
import {StyledProps, ThemedProps} from '../../modules/common';
import {styled} from 'styled-components';
import {LANGUAGES} from '../../modules/util/constants';

interface ReviewsIoRatingBarWidgetProps extends StyledProps, ThemedProps {
    lang?: string;
    widgetId: string;
    storeName: string;
}

const UnstyledReviewsIoRatingBarWidget = (
    props: ReviewsIoRatingBarWidgetProps
) => {
    const lang = props.lang ? props.lang : LANGUAGES.ENGLISH;
    const {className} = props;

    const [isVisible, setIsVisible] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const containerElement = containerRef.current;

        const observer = new MutationObserver(() => {
            const reviewStatElement =
                containerElement.querySelector('.R-TextBody');

            // Check if we have less than 100 reviews
            // If its true, remove the reviews number element
            if (reviewStatElement) {
                const numberText =
                    reviewStatElement.children[2]?.textContent?.trim();
                const numberValue = parseInt(numberText, 10);

                if (!isNaN(numberValue) && numberValue < 100) {
                    reviewStatElement.remove();
                }

                observer.disconnect();
                setIsVisible(true);
            }
        });

        // Observe the container for child elements being added
        observer.observe(containerElement, {
            childList: true,
            subtree: true,
        });

        // Cleanup function to disconnect the observer when the component unmounts
        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <div className={className}>
            <div
                ref={containerRef}
                style={{display: isVisible ? 'block' : 'none'}}
                className="reviews-io-rating-bar-widget"
                data-widget-id={props.widgetId}
                data-store-name={props.storeName}
                lang={lang}
            ></div>
        </div>
    );
};

const ReviewsIoRatingBarWidget = styled(UnstyledReviewsIoRatingBarWidget)`
    max-width: 100vw;
    min-height: 24px;
`;

export default ReviewsIoRatingBarWidget;
