import React from 'react';
import {styled} from 'styled-components';

const StyledSVG = styled.svg``;

const AppleIcon = ({fillColor}: {fillColor: string}) => (
    <StyledSVG
        width="14"
        height="16"
        viewBox="0 0 14 16"
        fill={fillColor}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M12.79 5.455c-.091.072-1.715.995-1.715 3.048 0 2.375 2.066 3.215 2.128 3.236-.01.051-.328 1.15-1.09 2.27-.678.986-1.386 1.97-2.465 1.97-1.078 0-1.355-.632-2.6-.632-1.212 0-1.643.653-2.63.653-.986 0-1.674-.912-2.465-2.032C1.037 12.652.297 10.609.297 8.67c0-3.111 2.003-4.76 3.976-4.76 1.048 0 1.921.694 2.579.694.626 0 1.603-.736 2.795-.736.452 0 2.075.041 3.143 1.587zM9.08 2.551c.494-.59.843-1.41.843-2.23 0-.113-.01-.228-.03-.321C9.09.03 8.136.54 7.56 1.213c-.452.518-.874 1.338-.874 2.168 0 .125.02.25.03.29.05.01.133.02.216.02.72 0 1.625-.486 2.148-1.14z" />
    </StyledSVG>
);

export default AppleIcon;
